export const ROUTE = 'ROUTE';
export const SET = `${ROUTE}/SET`;
export const SET_ROUTE = `${ROUTE}/SET_ROUTE`;
export const SET_ROUTES = `${ROUTE}/SET_ROUTES`;
export const SET_FILTERS = `${ROUTE}/SET_FILTERS`;
export const SET_FILTER = `${ROUTE}/SET_FILTER`;
export const SAVE_QUERY_PARAMS = `${ROUTE}/SAVE_QUERY_PARAMS`;
export const LOAD_QUERY_PARAMS = `${ROUTE}/LOAD_QUERY_PARAMS`;
export const RESET_QUERY_PARAMS = `${ROUTE}/RESET_QUERY_PARAMS`;

export const resetQueryParams = () => ({
    type: RESET_QUERY_PARAMS
});

export const set = (route: string, silent = false, replace?: boolean) => ({
    type: SET,
    route,
    silent,
    replace
});

export const setRoute = (route: string, silent = false, replace?: boolean) => ({
    type: SET_ROUTE,
    route,
    silent,
    replace
});

export const setRoutes = (routes) => ({
    type: SET_ROUTES,
    routes
});

export const setFilters = (filters: Record<string, unknown>, replace?: boolean) => ({
    type: SET_FILTERS,
    filters,
    replace
});

export const setFilter = (filterName: string, filterValue: unknown, replace?: boolean) => ({
    type: SET_FILTER,
    filterName,
    filterValue,
    replace
});

export const saveQueryParams = (routeName, queryParamsToSave) => ({
    type: SAVE_QUERY_PARAMS,
    routeName,
    queryParamsToSave
});

export const loadQueryParams = (routeName) => ({
    type: LOAD_QUERY_PARAMS,
    routeName
});
