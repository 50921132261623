import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLoginRoute, signInLink } from '@he-novation/config/paths/herawLoginPaths';
import strToQueryParams from '@he-novation/config/paths/utils/strToQueryParams';
import { UserAccount } from '@he-novation/config/types/user.types';
import * as sockets from '@he-novation/config/utils/sockets/sockets.client';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { DesignSystemProvider } from '@he-novation/design-system/DesignSystemProvider';
import { Theme } from '@he-novation/design-system/enums';
import { fetchUserAppInfos } from '@he-novation/front-shared/async/user.async';
import { useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import io from 'socket.io-client';
import App from './App';

import { localeAtom } from '$atoms/i18n-atoms';
import { workspaceAtom, workspacesAtom } from '$atoms/workspace-atoms';
import withProvider from '$components/HOC/withProvider';
import Link from '$components/router/Link';
import { setDatePickerConfiguration } from '$helpers/datepicker';
import { setActiveClientCss } from '$helpers/setActiveClientCSS';
import { useDocumentTitle } from '$hooks/useDocumentTitle';
import { useFeedbackModal } from '$hooks/useFeedbackModal';
import { useSocketIO } from '$hooks/useSocketIO';
import { useTranslate } from '$hooks/useTranslate';
import { useWorkspaceMembers } from '$hooks/useWorkspaceMembers';
import { useWorkspaceSocket } from '$hooks/useWorkspaceSocket';
import { setSize } from '$redux/config/configActions';
import { set } from '$redux/route/routeActions';
import store from '$redux/store';
import { setAccount, setAppInfos } from '$redux/user/userActions';

function useInitialFetch({
    host,
    locale,
    account
}: {
    host: string;
    locale: 'fr' | 'de' | 'en';
    account: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { openFeedbackModal } = useFeedbackModal();
    const { subscribe, unsubscribe } = useSocketIO();
    const { fetchWorkspaceMembers } = useWorkspaceMembers();

    const workspace = useAtomValue(workspaceAtom);

    const { subscribeWorkspaceSocket, unsubscribeWorkspaceSocket } = useWorkspaceSocket();

    useEffect(() => {
        return () => {
            unsubscribe();
            unsubscribeWorkspaceSocket();
        };
    }, []);

    return async function initialFetch(): Promise<{
        locale: 'en' | 'de' | 'fr';
        isTeamsApp: boolean;
    }> {
        const { login } = strToQueryParams(window.location.href);
        initWebsockets(host);
        let isTeamsApp = false;
        const isCast = window.location.pathname.startsWith('/cast/');

        if (!account && !isCast) {
            window.location.href = signInLink('fr', {
                redirect: window.location.pathname.replace('?', '&')
            });
            return { locale, isTeamsApp };
        }

        if (account) {
            dispatch(setAccount(account));
            isTeamsApp = account.isTeamsApp;
            await Promise.all([
                fetchUserAppInfos().then((r) => {
                    dispatch(setAppInfos(r));
                    return r;
                })
            ]);

            if (!isCast && workspace) {
                fetchWorkspaceMembers(workspace.name);
                if (login) {
                    dispatch(set(getLoginRoute(workspace.name, workspace.features)));
                }
            }
        }

        const action = async () => {
            openFeedbackModal(t('misc.New version available'), 0, {
                extraButtons: [
                    {
                        children: t('common.Reload'),
                        onClick: () => window.location.reload(),
                        tone: ButtonTone.Outlined,
                        theme: Theme.Light
                    }
                ]
            });
        };

        subscribe?.({
            room: 'misc',
            actions: {
                sioMiscReload: action,
                sioMiscNewVersion: action
            },
            socket: 'misc'
        });

        subscribeWorkspaceSocket();
        setDatePickerConfiguration(locale);
        return { locale, isTeamsApp };
    };
}

export function AppContainerInner({
    host,
    locale,
    account
}: {
    host: string;
    locale: 'fr' | 'de' | 'en';
    account: UserAccount;
}) {
    useHydrateAtoms([
        [localeAtom, locale],
        [workspaceAtom, account?.workspaces.find((workspace) => workspace.current) || null],
        [workspacesAtom, account ? account.workspaces : null]
    ]);
    const dispatch = useDispatch();

    const fetch = useInitialFetch({ host, locale, account });

    const workspace = useAtomValue(workspaceAtom);

    const [data, setData] = useState<{ locale: 'fr' | 'de' | 'en'; isTeamsApp: boolean } | null>(
        null
    );

    useDocumentTitle();

    useEffect(() => {
        dispatch(setSize(window.innerWidth, window.innerHeight));
        window.addEventListener('resize', () =>
            dispatch(setSize(window.innerWidth, window.innerHeight))
        );
        fetch().then(({ locale, isTeamsApp }) => {
            setData({
                locale,
                isTeamsApp
            });
        });
    }, []);

    useEffect(() => {
        if (workspace) setActiveClientCss(workspace.name);
    }, [workspace]);

    return data ? (
        <DesignSystemProvider locale={data.locale} RouterLinkComponent={Link}>
            <App isTeamsApp={data.isTeamsApp} />
        </DesignSystemProvider>
    ) : (
        <Loader />
    );
}

export const AppContainer = withProvider(store)(AppContainerInner);

function initWebsockets(websocketHost: string) {
    const host =
        process.env.WEBSOCKET_HOST && process.env.WEBSOCKET_HOST !== 'NULL'
            ? process.env.WEBSOCKET_HOST
            : websocketHost;
    const hostAndPort = host + ':443';
    sockets.misc.clientInit(io, hostAndPort);
    sockets.activity.clientInit(io, hostAndPort);
    sockets.user.clientInit(io, hostAndPort);
    sockets.entities.clientInit(io, hostAndPort);
    sockets.events.clientInit(io, hostAndPort);
    sockets.items.clientInit(io, hostAndPort);
    sockets.projects.clientInit(io, hostAndPort);
    sockets.archives.clientInit(io, hostAndPort);
    sockets.folder.clientInit(io, hostAndPort);
    sockets.note.clientInit(io, hostAndPort);
    sockets.subtitles.clientInit(io, hostAndPort);
    sockets.folders.clientInit(io, hostAndPort);
    sockets.casts.clientInit(io, hostAndPort);
    sockets.customFields.clientInit(io, hostAndPort);
    sockets.client.clientInit(io, hostAndPort);
    sockets.workspace.clientInit(io, hostAndPort);
    sockets.workspaceAdminUsers.clientInit(io, hostAndPort);
    sockets.workspaceTeams.clientInit(io, hostAndPort);
    sockets.workspaceUsers.clientInit(io, hostAndPort);
}

export default AppContainer;
