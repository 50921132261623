import { compose, pick, pipe, property } from 'lodash/fp';
import { ROUTE } from './routeActions';

import { deserialize } from '$components/Filters/helpers';
import { RouteState } from '$redux/route/routeReducer';
import type { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => RouteState = property(ROUTE);

export const routeSelector = compose(
    ({ configRoute, route, params, queryParams }: RouteState) => ({
        configRoute: configRoute || '',
        route,
        params,
        queryParams
    }),
    stateSelector
);

export const routeParamsSelector = compose(
    ({ params }) => ({ routeParams: params }),
    routeSelector
);

export const filtersSelector = compose(
    (filters) => ({ filters }) as { filters: Record<string, any> },
    (filters) => deserialize(filters),
    property('queryParams.filters'),
    stateSelector
);

export const filtersLengthSelector = compose(
    ({ filters }: { filters: Record<string, unknown> }) => ({
        filtersLength: Object.keys(filters)
            .filter((filter) => filter !== 'query')
            .reduce(
                (acc, curr) =>
                    Array.isArray(filters[curr]) ? acc + filters[curr].length : acc + 1,
                0
            )
    }),
    filtersSelector
);
export const routeParamAsPropSelector = (param: string) =>
    pipe(stateSelector, property('params'), pick(param));

export const queryParamAsPropSelector = (queryParam: string) =>
    pipe(stateSelector, property('queryParams'), pick(queryParam));

export const fileUrlInformationSelector = pipe(
    routeParamsSelector,
    property('routeParams'),
    pick(['branding', 'folderUuid', 'fileUuid', 'fileVersion', 'comparisonVersion']),
    (fileUrlInformation: Record<string, string>) => ({
        fileUrlInformation: {
            ...fileUrlInformation,
            fileVersion: parseInt(fileUrlInformation.fileVersion),
            comparisonVersion: fileUrlInformation.comparisonVersion
                ? parseInt(fileUrlInformation.comparisonVersion)
                : null
        }
    })
);

export const castFileUuidRouteSelector = pipe(
    routeParamsSelector,
    property('routeParams.castFileUuid')
);

export const castUidRouteSelector = pipe(routeParamsSelector, property('routeParams.castUid'));
