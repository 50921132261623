import { z } from 'zod';
import { AWSBucketInfos } from './aws.types';

const watermarkImageCoverOptions = z.object({
    fit: z.literal('cover'),
    opacity: z.number()
});

const watermarkImageFitOptions = z.object({
    fit: z.enum(['20', '50', 'original']),
    opacity: z.number(),
    alignment: z
        .object({
            x: z.enum(['left', 'center', 'right']),
            y: z.enum(['top', 'center', 'bottom'])
        })
        .optional()
});

export const watermarkImageOptionsSchema = z.union([
    watermarkImageCoverOptions,
    watermarkImageFitOptions
]);

export type WatermarkImageOptions = z.infer<typeof watermarkImageOptionsSchema>;

export const watermarkTextOptionsSchema = z.object({
    size: z.enum(['small', 'medium', 'large']).optional(),
    alignment: z
        .object({
            x: z.enum(['left', 'center', 'right']),
            y: z.enum(['top', 'center', 'bottom'])
        })
        .optional(),
    opacity: z.number().optional()
});

export type WatermarkTextOptions = z.infer<typeof watermarkTextOptionsSchema>;

export const watermarkSchema = z.object({
    image: z
        .object({
            uid: z.string(),
            options: watermarkImageOptionsSchema.optional()
        })
        .optional(),
    userInfos: z
        .object({
            enabled: z.boolean(),
            options: watermarkTextOptionsSchema.optional()
        })
        .optional(),
    customText: z
        .object({
            text: z.string(),
            options: watermarkTextOptionsSchema.optional()
        })
        .optional()
});

export type WatermarkSchema = z.infer<typeof watermarkSchema>;

namespace Watermark {
    export type Position = {
        mode: 'absolute' | 'relative';
        x: number;
        y: number;
    };

    export type Alignment = {
        mode: 'align';
        x: 'left' | 'center' | 'right';
        y: 'top' | 'center' | 'bottom';
    };

    export type FixedSize = {
        mode: 'fixed';
    };

    export type RelativeOrAbsotluteSize = {
        mode: 'absolute' | 'relative';
        width: number;
        height?: number;
    };

    export type Size = FixedSize | RelativeOrAbsotluteSize;

    export type Offset = {
        mode: 'absolute' | 'relative';
        x: number;
        y: number;
    };

    export type CoverFit = {
        mode: 'cover';
    };

    export type NoFit = {
        mode: 'none';
        size: Watermark.Size;
        position: Watermark.Position | Watermark.Alignment;
        offset: Watermark.Offset;
    };
}

export type Watermark = {
    opacity: number;
} & { fit: Watermark.CoverFit | Watermark.NoFit } & (
        | { url: string }
        | { bucket: AWSBucketInfos; key: string }
    );
