import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';

import { ModalName } from '$atoms/modal-atom';
import modals from '$components/modal/modals';
import { useModal } from '$hooks/useModal';
import { useOnKeyDown } from '$hooks/useOnKeyDown';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { routeSelector } from '$redux/route/routeSelectors';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';

export default function Modal() {
    const dispatch = useDispatch();

    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    const { queryParams } = useSelector(routeSelector);

    const { openModal, closeModal, modal, payload: modalPayload, extraParams } = useModal();

    const [disableOverlay, setDisableOverlay] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalLeaving, setModalLeaving] = useState(false);

    useEffect(() => {
        setDisableOverlay(modal && modalPayload ? !!modalPayload.disableOverlay : false);
        if (modal) {
            dispatch(toggleKeyboardListeners(false));
        }

        if (modal && !showModal) {
            setTimeout(() => {
                setShowModal(true);
            }, 1);
        }
    }, [modal, showModal, modalPayload]);

    const close = useCallback(() => {
        dispatch(toggleKeyboardListeners(true));

        setModalLeaving(true);

        setTimeout(() => {
            setShowModal(false);
            setModalLeaving(false);
            setDisableOverlay(false);

            closeModal();
        }, 300);
    }, []);

    useOnKeyDown(
        'escape',
        () => {
            if (modal) close();
        },
        [modal],
        {
            useKeyboardListenersSelector: false
        }
    );

    const Comp = modal && modals[modal];

    useEffect(() => {
        if (queryParams.modal) {
            openModal(queryParams.modal as ModalName, null, queryParams);
        } else {
            closeModal();
        }
    }, [queryParams.modal]);

    return modal ? (
        <ModalWrapper
            className={modal}
            disableOverlay={disableOverlay}
            isVisible={!!modal}
            isModalVisible={showModal}
            isModalLeaving={modalLeaving}
            isTop={modalPayload?.alignTop}
            onClickOutside={modalPayload && modalPayload.disableClickOutside ? undefined : close}
        >
            {Comp ? (
                <Suspense fallback={<Loader />}>
                    <Comp
                        closeModal={closeModal}
                        isSmallScreen={isSmallScreen}
                        payload={modalPayload || {}}
                        extraParams={extraParams}
                    />
                </Suspense>
            ) : (
                <div className={'c-empty-modal'} />
            )}
        </ModalWrapper>
    ) : null;
}
