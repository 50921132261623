import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_WORKSPACE,
    API_WORKSPACE_CACHE,
    API_WORKSPACE_CAST_STYLE,
    API_WORKSPACE_DOMAIN,
    API_WORKSPACE_OIDC,
    API_WORKSPACE_OWNERSHIP,
    API_WORKSPACE_PREFERENCES,
    API_WORKSPACE_STATS,
    API_WORKSPACE_STORAGE,
    API_WORKSPACE_STYLES,
    API_WORKSPACE_TERMS,
    API_WORKSPACE_USER
} from '@he-novation/config/apis/workspace-api';
import {
    Client,
    ClientDomain,
    ClientOIDC,
    ClientStats,
    ClientUser
} from '@he-novation/config/types/client.types';
import {
    type ClientOIDCBody,
    type ClientPreferencesSchema,
    ClientUserUpdateBody,
    type DefaultCastStyleSchema,
    WorkspaceBody
} from '@he-novation/config/types/payloads/client.payload';
import type {
    WorkspaceAdminUpdateBody,
    WorkspaceUserBody
} from '@he-novation/config/types/payloads/workspace.payload';
import { API_WORKSPACE_PUBLIC } from '../../config/apis/public/workspace-public-api';
import { download } from './file.async';

export const clientPreferencesUpdate = async (
    workspaceName: string,
    body: Partial<ClientPreferencesSchema>
) =>
    buildApiCall(API_WORKSPACE_PREFERENCES.PATCH)({
        params: { workspaceName },
        body
    });

export const fetchClient = (workspaceName: string): Promise<Client> =>
    buildApiCall(API_WORKSPACE.GET)({
        params: {
            workspaceName
        }
    }).then(({ created, ...r }) => {
        return {
            created: new Date(created),
            ...r
        };
    });

export const asyncClientsFetch = (): Promise<Client[]> =>
    buildApiCall(API_WORKSPACE.LIST)({}).then((r) =>
        r.map(({ created, ...r }) => {
            return {
                created: new Date(created),
                ...r
            };
        })
    );

export const fetchClientStyles = (workspaceName: string) =>
    buildApiCall(API_WORKSPACE_STYLES.GET)({
        params: { workspaceName }
    });

export const asyncClientDomainsFetch = (workspaceName: string): Promise<ClientDomain[]> =>
    buildApiCall(API_WORKSPACE_DOMAIN.LIST)({ params: { workspaceName } });

export const asyncClientDomainRefresh = (workspaceName: string, domainUuid: string) =>
    buildApiCall(API_WORKSPACE_DOMAIN.PUT)({ params: { workspaceName, domainUuid } });

export const asyncClientDomainDelete = (workspaceName: string, domainUuid: string) =>
    buildApiCall(API_WORKSPACE_DOMAIN.DELETE)({ params: { workspaceName, domainUuid } });

export const asyncClientDomainAdd = (workspaceName: string, name: string): Promise<ClientDomain> =>
    buildApiCall(API_WORKSPACE_DOMAIN.POST)({
        params: { workspaceName },
        body: { name }
    });

export const clientUsersFetch = (
    workspaceName: string,
    admin: boolean = false
): Promise<ClientUser[]> =>
    buildApiCall(API_WORKSPACE_USER.LIST)({
        params: { workspaceName },
        query: admin ? { admin } : undefined
    }).then((r) =>
        r.map((g) => ({
            ...g,
            created: g.created && new Date(g.created),
            lastUsed: g.lastUsed && new Date(g.lastUsed),
            deleteRequested: g.deleteRequested && new Date(g.deleteRequested),
            expires: g.expires && new Date(g.expires)
        }))
    );

export const asyncClientUserAdd = (workspaceName: string, payload: WorkspaceUserBody) =>
    buildApiCall(API_WORKSPACE_USER.POST)({
        params: { workspaceName },
        body: payload
    });

export const clientUserDelete = (workspaceName: string, userUuid: string) =>
    buildApiCall(API_WORKSPACE_USER.DELETE)({
        params: { workspaceName, userUuid }
    });

export const asyncClientUserEdit = (
    workspaceName: string,
    userUuid: string,
    body: ClientUserUpdateBody
) =>
    buildApiCall(API_WORKSPACE_USER.PUT)({
        params: { workspaceName, userUuid },
        body
    });

export const asyncWorkspaceCreate = (payload: WorkspaceBody): Promise<Client> =>
    buildApiCall(API_WORKSPACE.POST)({
        body: payload
    });

export const asyncWorkspaceAdminClientUpdate = (
    workspaceName: string,
    body: WorkspaceAdminUpdateBody
): Promise<Client> =>
    buildApiCall(API_WORKSPACE.PUT)({
        params: {
            workspaceName
        },
        body
    });

export const clientFetchTerms = (workspaceName: string, locale: 'fr' | 'en' | 'de') =>
    buildApiCall(API_WORKSPACE_PUBLIC.TERMS_GET)({
        params: { workspaceName, locale }
    });

export const asyncClientTermsSet = (
    workspaceName: string,
    locale: 'fr' | 'en' | 'de',
    terms: string
) =>
    buildApiCall(API_WORKSPACE_TERMS.PUT)({
        params: { workspaceName, locale },
        body: { terms }
    });

export const clientFetchStats = (workspaceName: string): Promise<ClientStats> =>
    buildApiCall(API_WORKSPACE_STATS.GET)({
        params: { workspaceName }
    });

export const asyncClientOIDCLinkFetch = (): Promise<{
    label?: string | null;
    href: string;
} | null> => buildApiCall(API_WORKSPACE_PUBLIC.OIDC_LINK_GET)({});

export const asyncClientOIDCFetch = (workspaceName: string): Promise<ClientOIDC | null> =>
    buildApiCall(API_WORKSPACE_OIDC.GET)({ params: { workspaceName } });

export const asyncClientOIDCUpdate = (workspaceName: string, body: ClientOIDCBody) =>
    buildApiCall(API_WORKSPACE_OIDC.PATCH)({
        params: {
            workspaceName
        },
        body: body
    });

export const asyncClientDefaultCastStyleSet = (
    workspaceName: string,
    castStyle: DefaultCastStyleSchema
) =>
    buildApiCall(API_WORKSPACE_CAST_STYLE.PUT)({
        params: { workspaceName },
        body: castStyle
    });

export const updateClientStyles = (workspaceName: string, styles: { primary: string }) =>
    buildApiCall(API_WORKSPACE_STYLES.PUT)({
        params: { workspaceName },
        body: styles
    });

export const asyncFetchClientStorage = (workspaceName: string) =>
    buildApiCall(API_WORKSPACE_STORAGE.GET)({ params: { workspaceName } });

export const asyncClientUserTransfer = (
    workspaceName: string,
    userUuid: string,
    userDstUuid: string
) =>
    buildApiCall(API_WORKSPACE_USER.TRANSFER)({ params: { workspaceName, userUuid, userDstUuid } });

export const asyncUpdateClientCurrentWorkspace = (workspaceName: string) =>
    buildApiCall(API_WORKSPACE.CURRENT_PUT)({ params: { workspaceName } });

export const asyncTransferWorkspaceOwnership = (workspaceName: string, userUuid: string) =>
    buildApiCall(API_WORKSPACE_OWNERSHIP.TRANSFER)({
        params: { workspaceName },
        body: {
            ownerUuid: userUuid
        }
    });

export const userReportFetch = (workspaceName: string, name: string) =>
    buildApiCall(API_WORKSPACE_STATS.USER_REPORT_GET)({
        params: { workspaceName },
        options: { rawResponse: true }
    }).then(async (r: any) => download(URL.createObjectURL(await r.blob()), name));

export const workspaceCacheClear = (workspaceName: string) =>
    buildApiCall(API_WORKSPACE_CACHE.DELETE)({
        params: { workspaceName }
    });
