import styles from './SidePanelFolderSettings.module.css';
import { useCallback, useRef, useState } from 'react';
import type { FormStore } from 'react-modular-forms/dist/declarations/FormStore';
import { admittanceLink } from '@he-novation/config/paths/herawLoginPaths';
import { FullFolder } from '@he-novation/config/types/folder.types';
import { WatermarkSchema } from '@he-novation/config/types/watermark.types';
import { acl, featureACL } from '@he-novation/config/utils/acl';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { CopyField } from '@he-novation/design-system/components/form/CopyField/CopyField';
import {
    FormField,
    FormFields,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import { useAtomValue } from 'jotai';
import { set } from 'lodash';
import { FormValues } from '../SidePanelFolder';
import { SidePanelFolderSettingsWatermark } from './SidePanelFolderSettingsWatermark/SidePanelFolderSettingsWatermark';

import { workspaceFeaturesAtom } from '$atoms/workspace-atoms';
import { SidePanelFolderSettingsCheckbox } from '$components/SidePanel/SidePanelFolderSettings/SidePanelFolderSettings/SidePanelFolderSettingsCheckbox/SidePanelFolderSettingsCheckbox';
import { useTranslate } from '$hooks/useTranslate';

type SidePanelFolderSettingsProps = {
    folder: FullFolder;
    onChange: (settings: FormValues, watermark?: WatermarkSchema) => void;
    onRecursiveButtonClick: (settings: FormValues, watermark?: WatermarkSchema) => void;
};

export function SidePanelFolderSettings({
    folder,
    onChange,
    onRecursiveButtonClick
}: SidePanelFolderSettingsProps) {
    const { t, locale } = useTranslate();

    const form = useRef<FormStore>(null);

    const readOnly = !acl.folders.modify(folder.role);
    const workspaceFeatures = useAtomValue(workspaceFeaturesAtom);
    const folderAdmittance = folder.metadata?.admittance ? folder.metadata.admittance : null;

    const [watermark, setWatermark] = useState<WatermarkSchema | undefined>(folder.watermark);

    const submit = () => {
        const settings = form.current?.getValues();
        console.log('settings', settings);

        if (settings) {
            const watermark = fetchWatemark(settings);
            setWatermark(watermark);
            onChange(settings, watermark);
        }
    };

    const fetchWatemark = useCallback(
        (settings: FormValues) => {
            const w: WatermarkSchema = {
                ...watermark,
                image: settings['wimg.uid']
                    ? {
                          uid: settings['wimg.uid'],
                          options: {
                              fit: settings['wimg.fit'] || 'cover',
                              opacity: parseFloat(settings['wimg.opacity']) || 1,
                              alignment: {
                                  x: settings['wimg.alignment.x'] || 'center',
                                  y: settings['wimg.alignment.y'] || 'center'
                              }
                          }
                      }
                    : undefined,
                userInfos: settings.watermarkUserInfos
                    ? {
                          enabled: true,
                          options: {
                              size: settings['wui.size'] || 'medium',
                              opacity: parseFloat(settings['wui.opacity']) || 1,
                              alignment: {
                                  x: settings['wui.alignment.x'] || 'center',
                                  y: settings['wui.alignment.y'] || 'center'
                              }
                          }
                      }
                    : {
                          enabled: false
                      },
                customText: settings.watermarkCustomText
                    ? {
                          text: settings.watermarkCustomText,
                          options: {
                              size: settings['wct.size'] || 'medium',
                              opacity: parseFloat(settings['wct.opacity']) || 1,
                              alignment: {
                                  x: settings['wct.alignment.x'] || 'center',
                                  y: settings['wct.alignment.y'] || 'center'
                              }
                          }
                      }
                    : undefined
            };
            return w;
        },
        [watermark]
    );

    const formId = 'folder-settings-form';

    return (
        <ModularForm id={formId} ref={form} className={styles.settings}>
            {!readOnly && (
                <>
                    {featureACL.admittance(workspaceFeatures) && (
                        <SidePanelFolderSettingsCheckbox
                            formId={formId}
                            name="admittance"
                            id="folder-settings-form-admittance"
                            label={t('folder.Admittance link')}
                            onChange={() => submit()}
                            checked={!!folder.admittance}
                        >
                            <CopyField
                                id="folder-settings-form-admittance-link"
                                theme={Theme.Dark}
                                value={admittanceLink(locale, folder.workspace.name, folder.uuid)}
                            />

                            <FormFields
                                formId={formId}
                                theme={Theme.Dark}
                                fields={[
                                    {
                                        name: 'admittance-role',
                                        id: 'folder-admittance-role',
                                        type: 'select',
                                        onChange: () => submit(),
                                        value: folderAdmittance?.role,
                                        children: acl.content.showRoles().map((key) => (
                                            <option value={key} key={key}>
                                                {t(`folder.__${key}`)}
                                            </option>
                                        ))
                                    },
                                    {
                                        name: 'admittance-download',
                                        id: 'folder-admittance-download',
                                        type: 'checkbox',
                                        onChange: () => submit(),
                                        checked: folderAdmittance?.download,
                                        label: t('common.Download')
                                    },
                                    {
                                        name: 'admittance-export',
                                        id: 'folder-admittance-export',
                                        onChange: () => submit(),
                                        checked: folderAdmittance?.export,
                                        type: 'checkbox',
                                        label: t('folder.Export')
                                    }
                                ]}
                            />
                        </SidePanelFolderSettingsCheckbox>
                    )}

                    <SidePanelFolderSettingsCheckbox
                        formId={formId}
                        id="folder-settings-form-export"
                        name="export"
                        onChange={(e) => {
                            if (e.target.checked) {
                                (
                                    document.getElementById(
                                        'folder-option-export'
                                    ) as HTMLInputElement
                                ).value = 'always';
                            }
                            submit();
                        }}
                        label={t('folder.Allow exports')}
                        checked={folder.exportMode !== 'never'}
                    >
                        <FormField
                            formId={formId}
                            name="export_mode"
                            id="folder-option-export"
                            type="select"
                            value={folder.exportMode}
                            onChange={() => submit()}
                        >
                            <option value="always">{t('folder.All files')}</option>
                            <option value="final">{t('folder.Final versions')}</option>
                        </FormField>
                    </SidePanelFolderSettingsCheckbox>

                    <SidePanelFolderSettingsCheckbox
                        onChange={() => submit()}
                        formId={formId}
                        label={t('folder.Handle HTML bundles')}
                        id="folder-plugin-bundles"
                        name="trigger-bundles"
                        checked={folder.handlesHtmlBundles}
                    />
                </>
            )}

            {featureACL.watermark(workspaceFeatures) && (
                <SidePanelFolderSettingsWatermark watermark={watermark || {}} submit={submit} />
            )}

            <Button
                className={styles.recursiveButton}
                type={'button'}
                tone={ButtonTone.Outlined}
                icon="list"
                iconAfter
                onClick={() => {
                    const settings = form.current?.getValues();
                    if (settings) {
                        onRecursiveButtonClick(settings, watermark);
                    }
                }}
            >
                {t('folder.Apply to subfolders')}
            </Button>
        </ModularForm>
    );
}
