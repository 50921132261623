import { z } from 'zod';
import { UserFileType } from '../user-file.types';
import { workspaceParamsSchema } from './workspace.payload';

export const userFilesSelectQuerySchema = z.object({
    type: z.nativeEnum(UserFileType).optional().nullable()
});

export type UserFilesSelectQuery = z.infer<typeof userFilesSelectQuerySchema>;

export const userFileCreateBodySchema = z.object({
    type: z.nativeEnum(UserFileType),
    size: z.number(),
    contentType: z.string()
});

export type UserFileCreateBody = z.infer<typeof userFileCreateBodySchema>;

export const userFileUpdateUrlBodySchema = z.object({
    url: z.string()
});

export type UserFileUpdateUrlBody = z.infer<typeof userFileUpdateUrlBodySchema>;

export const userFileSpecificParamsSchema = workspaceParamsSchema.extend({
    userFileUid: z.string()
});

export type UserFileSpecificParams = z.infer<typeof userFileSpecificParamsSchema>;
