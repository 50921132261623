import styles from './WatermarkPreview.module.css';
import React from 'react';
import {
    WatermarkImageOptions,
    WatermarkTextOptions
} from '@he-novation/config/types/watermark.types';

export type WatermarkPreviewProps = {
    userInfos?: {
        options: WatermarkTextOptions;
    };
    img?: {
        options: WatermarkImageOptions;
    } & {
        url: string;
    };
    customText?: {
        text: string;
        options?: WatermarkTextOptions;
    };
};

function getStyleFromTextOptions(options: WatermarkTextOptions): React.CSSProperties {
    let style: React.CSSProperties = {};
    switch (options.size) {
        case 'small':
            style = {
                fontSize: '3px'
            };
            break;
        default:
        case 'medium':
            style = {
                fontSize: '5px'
            };
            break;
        case 'large':
            style = {
                fontSize: '8px'
            };
            break;
    }

    style.position = 'absolute';
    switch (options.alignment?.x) {
        case 'left':
            style.left = '5%';
            style.alignItems = 'flex-start';
            break;
        default:
        case 'center':
            style.left = '50%';
            style.transform = 'translateX(-50%)';
            style.alignItems = 'center';
            break;
        case 'right':
            style.right = '5%';
            style.alignItems = 'flex-end';
            break;
    }

    switch (options.alignment?.y) {
        case 'top':
            style.top = '5%';
            break;
        case 'bottom':
            style.bottom = '5%';
            break;
        default:
        case 'center':
            style.top = '50%';
            style.transform = (style.transform ?? '') + 'translateY(-50%)';
            break;
    }
    style.opacity = options.opacity;

    return style;
}

export default function WatermarkPreview({ userInfos, img, customText }: WatermarkPreviewProps) {
    let imgStyle: React.CSSProperties = {};
    imgStyle.opacity = img?.options.opacity;
    imgStyle.position = 'absolute';
    switch (img?.options.fit) {
        case 'cover':
            imgStyle = {
                ...imgStyle,
                objectFit: img.options.fit,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
            };
            break;
        case '20':
            imgStyle = {
                ...imgStyle,
                objectFit: 'cover',
                width: '20%',
                height: '20%'
            };
            break;
        case '50':
            imgStyle = {
                ...imgStyle,
                objectFit: 'cover',
                width: '50%',
                height: '50%'
            };
            break;
        case 'original':
            imgStyle = {
                ...imgStyle,
                objectFit: 'none',
                width: '100%',
                height: '100%'
            };
            break;
    }

    if (img?.options.fit !== 'cover') {
        switch (img?.options.alignment?.x) {
            case 'left':
                imgStyle.left = '5%';
                break;
            case 'right':
                imgStyle.right = '5%';
                break;
            default:
            case 'center':
                imgStyle.left = '50%';
                imgStyle.transform = 'translateX(-50%)';
                break;
        }
        switch (img?.options.alignment?.y) {
            case 'top':
                imgStyle.top = '5%';
                break;
            case 'bottom':
                imgStyle.bottom = '5%';
                break;
            default:
            case 'center':
                imgStyle.top = '50%';
                imgStyle.transform = (imgStyle.transform ?? '') + 'translateY(-50%)';
                break;
        }
    }

    let userInfosDiv: React.ReactNode = null;
    if (userInfos) {
        const userInfosStyle = getStyleFromTextOptions(userInfos.options);
        userInfosDiv = (
            <div style={userInfosStyle} className={styles.userInfos}>
                <span>John Doe</span>
                <span>john.doe@heraw.com</span>
                <span>01/01/2000 00:00</span>
                <span>127.0.0.1</span>
            </div>
        );
    }

    let customTextDiv: React.ReactNode = null;
    if (customText) {
        const customTextStyle = getStyleFromTextOptions(
            customText.options || {
                alignment: {
                    x: 'center',
                    y: 'center'
                },
                size: 'medium',
                opacity: 50
            }
        );
        customTextDiv = (
            <div style={customTextStyle} className={styles.customText}>
                {customText.text}
            </div>
        );
    }

    return (
        <div className={styles.frame}>
            {img ? <img src={img.url} className={styles.img} style={imgStyle} /> : null}
            {userInfosDiv}
            {customTextDiv}
        </div>
    );
}
