import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import {
    breadcrumbSchema,
    folderContentSchema,
    folderMemberSchema,
    folderSizeSchema,
    fullFolderSchema,
    trashInfosSchema
} from '../types/folder.types';
import {
    oaDeprecatedFolderSpecificSchema,
    oaFolderCreateBody,
    oaFolderInviteBody,
    oaFolderParamsSchema,
    oaFolderSchema,
    oaFolderSpecificOptionalSchema,
    oaFolderTeamsBody,
    oaFolderUuidSchema,
    oaOptionalFolderUuidSchema,
    oaWorkspaceNameAndOptionalFolderUuidSchema
} from '../types/open-api/folder.open-api.types';
import {
    bulkDeleteFoldersBody,
    folderBasicParam,
    folderCopyTreeBody,
    folderCreateBody,
    folderInviteBody,
    folderModifyAccessBody,
    folderParamsSchema,
    folderSizeQuery,
    folderTeamsBody,
    folderUpdateBody,
    optionalFolderParamsSchema,
    optionalPendingSchema,
    workspaceNameFolderUuidAndUserUuidSchema
} from '../types/payloads/folder.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { folderContentFindResponse } from '../types/responses/folder.responses';
import { folderTeamSchema } from '../types/team.types';
import { watermarkSchema } from '../types/watermark.types';

export const API_FOLDER = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folders/:folderUuid',
        handlerMethod: 'findFolder',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'The requested folder',
                schema: fullFolderSchema
            }
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/folders/:folderUuid',
        handlerMethod: 'findFolder',
        description: 'Get a folder',
        zod: {
            params: oaFolderParamsSchema
        },
        responses: {
            200: {
                description: 'The requested folder',
                schema: oaFolderSchema
            }
        },
        publicApi: true,
        group: Group.Folders
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/folder/:folderUuid',
        handlerMethod: 'findFolder',
        description: 'Get a folder',
        zod: {
            params: oaFolderUuidSchema
        },
        responses: {
            200: {
                description: 'The requested folder',
                schema: oaFolderSchema
            }
        },
        publicApi: 'deprecated',
        group: Group.Folders
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folders/:folderUuid',
        handlerMethod: 'updateFolder',
        zod: {
            params: folderParamsSchema,
            body: folderUpdateBody
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folders',
        handlerMethod: 'createFolder',
        zod: {
            params: workspaceParamsSchema,
            body: folderCreateBody
        },
        responses: {
            200: {
                description: 'The created folder',
                schema: fullFolderSchema
            }
        }
    },

    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/folders',
        handlerMethod: 'createFolder',
        zod: {
            params: workspaceParamsSchema,
            body: oaFolderCreateBody
        },
        description: 'Create a folder',
        responses: {
            201: {
                description: 'Folder created',
                schema: oaFolderSchema
            }
        },
        publicApi: true,
        group: Group.Folders
    },

    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/folder',
        handlerMethod: 'createFolder',
        zod: {
            body: oaFolderCreateBody
        },
        description: 'Create a folder',
        responses: {
            201: {
                description: 'Folder created',
                schema: oaFolderSchema
            }
        },
        publicApi: 'deprecated',
        group: Group.Folders
    },

    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/folders/:folderUuid',
        handlerMethod: 'deleteFolder',
        zod: {
            params: oaFolderParamsSchema
        },
        responses: {
            204: {
                description: 'Folder deleted'
            }
        },
        description: 'Delete folder',
        publicApi: true,
        group: Group.Folders
    },

    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/folder/:folderUuid',
        handlerMethod: 'deleteFolder',
        zod: {
            params: oaDeprecatedFolderSpecificSchema
        },
        responses: {
            204: {
                description: 'Folder deleted'
            }
        },
        description: 'Delete folder',
        publicApi: 'deprecated',
        group: Group.Folders
    },

    MOVE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/folders/:sourceFolderUuid/move/:targetFolderUuid',
        handlerMethod: 'moveFolder',
        zod: {
            params: z.object({
                workspaceName: z.string(),
                sourceFolderUuid: z.string().uuid(),
                targetFolderUuid: z.string().uuid()
            })
        }
    },
    BULK_DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/folders',
        handlerMethod: 'bulkDeleteFolders',
        zod: {
            params: workspaceParamsSchema,
            body: bulkDeleteFoldersBody
        }
    },
    COPY_TREE: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folders/copy-tree',
        handlerMethod: 'copyFolderTree',
        zod: {
            params: workspaceParamsSchema,
            body: folderCopyTreeBody
        }
    },
    RESTORE: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folder-content/:folderUuid/restore',
        handlerMethod: 'restoreFolder',
        zod: {
            params: folderParamsSchema
        }
    },
    RECURSIVE_PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folders/:folderUuid/recursive',
        handlerMethod: 'recursiveUpdate',
        zod: {
            params: folderParamsSchema,
            body: z.object({
                folder: z.object({
                    handlesHtmlBundles: z.boolean().optional(),
                    metadata: z.any(),
                    admittance: z.boolean().optional(),
                    public: z.boolean().optional(),
                    watermark: watermarkSchema.optional(),
                    export_mode: z.string().optional(),
                    tags: z.array(z.string()).optional(),
                    labels: z.array(z.string()).optional(),
                    encrypted: z.boolean().optional(),
                    default_presets: z.boolean().optional(),
                    public_download: z.boolean().optional(),
                    public_password: z.string().optional(),
                    metadata_user: z.any()
                })
            })
        }
    },
    PATH_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folders/:folderUuid/path',
        handlerMethod: 'getPath',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'Folder breadcrumb',
                schema: breadcrumbSchema.array()
            }
        }
    },
    SIZE_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folders/:folderUuid/size',
        handlerMethod: 'getFolderSize',
        zod: {
            params: folderParamsSchema,
            query: folderSizeQuery
        },
        responses: {
            200: {
                description: 'Folder size',
                schema: folderSizeSchema
            }
        }
    }
} as const;

export const API_FOLDER_CONTENT = {
    GET: {
        method: ApiMethod.GET,
        path: [
            '/workspaces/:workspaceName/folder-content/:folderUuid?',
            '/workspaces/:workspaceName/folder-content'
        ],
        handlerMethod: 'getContent',
        zod: {
            params: optionalFolderParamsSchema,
            query: folderBasicParam
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/folders/:folderUuid/content',
        handlerMethod: 'getContent',
        description: 'Get folder content',
        zod: {
            params: folderParamsSchema,
            query: folderBasicParam
        },
        responses: {
            200: {
                description: "Requested folder's content",
                schema: folderContentFindResponse
            }
        },
        publicApi: true,
        group: Group.Folders
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/folder-content/:folderUuid',
        handlerMethod: 'getContent',
        description: 'Get folder content',
        zod: {
            params: oaFolderSpecificOptionalSchema,
            query: folderBasicParam
        },
        responses: {
            200: {
                description: "Requested folder's content",
                schema: folderContentFindResponse
            }
        },
        publicApi: 'deprecated',
        group: Group.Folders
    }
} as const;

export const API_FOLDER_TEAMS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folders/:folderUuid/teams',
        handlerMethod: 'listTeams',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'Folder teams',
                schema: folderTeamSchema.array()
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/folders/:folderUuid/teams',
        handlerMethod: 'setTeams',
        zod: {
            params: folderParamsSchema,
            body: folderTeamsBody
        }
    },
    PUT_OA: {
        method: ApiMethod.PUT,
        path: '/public/v1/workspaces/:workspaceName/folder/:folderUuid/teams',
        handlerMethod: 'setTeams',
        zod: {
            params: folderParamsSchema,
            body: oaFolderTeamsBody
        },
        responses: {
            204: {
                description: 'Folder teams have been updated'
            }
        },
        description: 'Changes project teams with access to folder',
        publicApi: true,
        group: Group.Folders
    },
    PUT_OA_DEPRECATED: {
        method: ApiMethod.PUT,
        path: '/public/v1/folder/:folderUuid/teams',
        handlerMethod: 'setTeams',
        zod: {
            params: oaDeprecatedFolderSpecificSchema,
            body: oaFolderTeamsBody
        },
        responses: {
            204: {
                description: 'Folder teams have been updated'
            }
        },
        description: 'Changes project teams with access to folder',
        publicApi: 'deprecated',
        group: Group.Folders
    }
} as const;

export const API_FOLDER_USER = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folders/:folderUuid/users',
        handlerMethod: 'getMembers',
        zod: {
            params: folderParamsSchema,
            query: optionalPendingSchema
        },
        responses: {
            200: {
                description: 'Folder members',
                schema: folderMemberSchema.array()
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folders/:folderUuid/users',
        handlerMethod: 'grantAccess',
        zod: {
            params: folderParamsSchema,
            body: folderInviteBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/folders/:folderUuid/users/:userUuid',
        handlerMethod: 'revokeAccess',
        zod: {
            params: workspaceNameFolderUuidAndUserUuidSchema
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folders/:folderUuid/users/:userUuid',
        handlerMethod: 'modifyFolderAccess',
        zod: {
            params: workspaceNameFolderUuidAndUserUuidSchema,
            body: folderModifyAccessBody
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/folders/:folderUuid/user',
        handlerMethod: 'grantAccess',
        zod: {
            params: folderParamsSchema,
            body: oaFolderInviteBody
        },
        responses: {
            204: {
                description: 'Access granted'
            }
        },
        description: 'Invite users to a folder',
        publicApi: true,
        group: Group.Folders
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/folder/:folderUuid/invite',
        handlerMethod: 'grantAccess',
        zod: {
            params: oaDeprecatedFolderSpecificSchema,
            body: oaFolderInviteBody
        },
        responses: {
            204: {
                description: 'Access granted'
            }
        },
        description: 'Invite users to a folder',
        publicApi: 'deprecated',
        group: Group.Folders
    }
} as const;

export const API_TRASH = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/trash',
        handlerMethod: 'getTrash',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'Trash infos',
                schema: trashInfosSchema
            }
        }
    }
} as const;
