import styles from './SettingsWorkspace.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    workspaceSettingsDashboardLink,
    workspaceSettingsInterfaceLink,
    workspaceSettingsLink,
    workspaceSettingsMiscLink,
    workspaceSettingsSecurityLink,
    workspaceSettingsSubscriptionLink,
    workspaceSettingsUsersLink
} from '@he-novation/config/paths/herawFrontUris';
import { ClientTier } from '@he-novation/config/types/db/enums';
import type { UserWorkspace } from '@he-novation/config/types/user.types';
import { WorkspaceFeature } from '@he-novation/config/types/workspace.types';
import { useAtomValue } from 'jotai';
import SettingsWorkspaceSubscription from './SettingsWorkspaceSubscription';

import { workspaceAtom } from '$atoms/workspace-atoms';
import { Header } from '$components/layout/Header/Header';
import { HeaderNav } from '$components/layout/Header/HeaderNavigation/HeaderNavigation';
import { View, ViewContent } from '$components/layout/View/View';
import { useTranslate } from '$hooks/useTranslate';
import { set } from '$redux/route/routeActions';
import { SettingsWorkspaceDashboard } from '$views/settings/SettingsWorkspace/SettingsWorkspaceDashboard';
import { SettingsWorkspaceInterface } from '$views/settings/SettingsWorkspace/SettingsWorkspaceInterface';
import { SettingsWorkspaceMembers } from '$views/settings/SettingsWorkspace/SettingsWorkspaceMembers';
import { SettingsWorkspaceMiscellaneous } from '$views/settings/SettingsWorkspace/SettingsWorkspaceMiscellaneous';
import { SettingsWorkspaceSecurity } from '$views/settings/SettingsWorkspace/SettingsWorkspaceSecurity';

type SettingsWorkspaceProps = { route: string };

const cleanRoute = (route: string) => route.replace(/\?.*$/g, '');

export function SettingsWorkspace({ route }: SettingsWorkspaceProps) {
    const dispatch = useDispatch();
    const workspace = useAtomValue(workspaceAtom)!;

    useEffect(() => {
        if (!route) {
            dispatch(set(workspaceSettingsLink(workspace.name, 'dashboard')));
        }
    }, []);

    const content = getContent(route);

    const settingsUserLinks = getSettingsWorkspaceLinks(workspace, workspace.features);

    return (
        <View id="view-settings-user" className={styles.settings}>
            <Header nav={settingsUserLinks} title={workspace.displayName} />
            <ViewContent className={formStyles.dark}>{content}</ViewContent>
        </View>
    );
}

function getSettingsWorkspaceLinks(
    workspace: UserWorkspace,
    features: WorkspaceFeature[]
): HeaderNav {
    const { t } = useTranslate();
    const links = [
        {
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsDashboardLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'dashboard'),
            icon: 'dashboard',
            children: t('common.Dashboard')
        },
        {
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsUsersLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'users'),
            icon: 'users',
            children: t('common.Workspace members')
        },
        {
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsInterfaceLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'interface'),
            icon: 'brush',
            children: t('settings.User interface'),
            disabled: !features.includes(WorkspaceFeature.Customization)
        },
        {
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsSecurityLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'security'),
            icon: 'lock',
            children: t('settings.Security'),
            disabled: !features.includes(WorkspaceFeature.Security)
        },
        {
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsMiscLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'misc'),
            icon: 'cog',
            children: t('common.Settings')
        }
    ];
    if (([ClientTier.freemium, ClientTier.teams] as ClientTier[]).includes(workspace.tier)) {
        links.push({
            matchRoute: (activeRoute) =>
                cleanRoute(activeRoute) === workspaceSettingsSubscriptionLink(workspace.name),
            href: workspaceSettingsLink(workspace.name, 'subscription'),
            icon: 'credit-card',
            children: t('settings.Workspace subscription')
        });
    }
    return links;
}

function getContent(activeRoute: string = 'profile'): ReactNode {
    switch (activeRoute) {
        case 'users':
            return <SettingsWorkspaceMembers />;
        case 'interface':
            return <SettingsWorkspaceInterface />;
        case 'security':
            return <SettingsWorkspaceSecurity />;
        case 'misc':
            return <SettingsWorkspaceMiscellaneous />;
        case 'subscription':
            return <SettingsWorkspaceSubscription />;
        case 'dashboard':
        default:
            return <SettingsWorkspaceDashboard />;
    }
}
