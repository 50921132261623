import styles from './SidePanelFolderSettingsWatermark.module.css';
import { useEffect, useState } from 'react';
import { UserFile, UserFileType } from '@he-novation/config/types/user-file.types';
import { WatermarkSchema } from '@he-novation/config/types/watermark.types';
import { GraphicItem } from '@he-novation/design-system/components/form/FormField/components/GraphicPicker/GraphicPicker';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import {
    asyncWorkspaceUserFileCreate,
    asyncWorkspaceUserFileDelete,
    asyncWorkspaceUserFilesFetch
} from '@he-novation/front-shared/async/workspaceUserFile.async';
import { useAtomValue } from 'jotai';
import { debounce } from 'lodash';
import { WatermarkImageOptions } from './WatermarkImageOptions';
import WatermarkPreview from './WatermarkPreview';
import { WatermarkTextOptions } from './WatermarkTextOptions';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import Accordion from '$components/Accordion/Accordion';
import { useTranslate } from '$hooks/useTranslate';

export function SidePanelFolderSettingsWatermark({
    watermark,
    submit
}: {
    watermark: WatermarkSchema;
    submit: () => void;
}) {
    const [wmUserFiles, setWmUserFiles] = useState<UserFile[]>([]);

    const [wmUserFile, setWmUserFile] = useState<UserFile | undefined>();

    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        asyncWorkspaceUserFilesFetch(workspaceName, UserFileType.WATERMARK).then((wmUserFiles) => {
            setWmUserFiles(wmUserFiles);
            if (watermark.image?.uid) {
                const userFile = wmUserFiles.find((f) => f.uid === watermark.image!.uid);
                setWmUserFile(userFile);
            }
        });
    }, []);

    const { t } = useTranslate();

    const debouncedInput = debounce(() => {
        submit();
    }, 500);

    return (
        <div className={styles.container}>
            <Accordion title={t('settings.Watermark')} icon="lock" isOpen={false} className="p-0">
                <div className={styles.preview}>
                    <WatermarkPreview
                        userInfos={
                            watermark.userInfos?.options
                                ? { options: watermark.userInfos.options }
                                : undefined
                        }
                        img={
                            wmUserFile && wmUserFile.url && watermark.image?.options
                                ? { url: wmUserFile.url, options: watermark.image.options }
                                : undefined
                        }
                        customText={watermark.customText}
                    />
                </div>
                <div className={styles.group}>
                    <h2 className={styles.title}>{t('settings.Image')}</h2>
                    <div className={styles.imageForm}>
                        <FormField
                            formId="folder-settings-form"
                            name="wimg.uid"
                            type={'graphic-picker'}
                            label={t('settings.Logo')}
                            value={wmUserFile}
                            values={wmUserFiles}
                            className={styles.graphicPicker}
                            onAdd={(file: File) => {
                                asyncWorkspaceUserFileCreate(
                                    workspaceName,
                                    UserFileType.WATERMARK,
                                    file
                                ).then((userFile) => {
                                    setWmUserFiles([...wmUserFiles, userFile]);
                                });
                            }}
                            onChange={(item: GraphicItem | null) => {
                                const userFile = item
                                    ? wmUserFiles.find((f) => f.uid == item.uid)
                                    : undefined;

                                setWmUserFile(userFile);

                                setTimeout(() => {
                                    submit();
                                }, 0);
                            }}
                            onRemove={({ uid }: GraphicItem) => {
                                asyncWorkspaceUserFileDelete(workspaceName, uid).then(() => {
                                    setWmUserFiles(wmUserFiles.filter((f) => f.uid !== uid));
                                });
                                submit();
                            }}
                        />

                        {
                            <WatermarkImageOptions
                                options={watermark.image?.options}
                                submit={submit}
                                prefix="wimg"
                            />
                        }
                    </div>
                </div>
                <div className={styles.group}>
                    <h2 className={styles.title}>{t('settings.User ID')}</h2>
                    <div className={styles.row}>
                        <FormField
                            formId="folder-settings-form"
                            name="watermarkUserInfos"
                            label={t('settings.Display user name, IP address and time')}
                            className="is-switch"
                            type="checkbox"
                            checked={!!watermark.userInfos?.enabled}
                            onChange={submit}
                        />
                    </div>

                    <WatermarkTextOptions
                        options={watermark.userInfos?.options || {}}
                        submit={submit}
                        prefix="wui"
                    />
                </div>
                <div className={styles.group}>
                    <h2 className={styles.title}>{t('settings.Custom text')}</h2>
                    <div className={styles.row}>
                        <FormField
                            formId="folder-settings-form"
                            name="watermarkCustomText"
                            label={t('settings.Custom text')}
                            type="text"
                            value={watermark.customText?.text}
                            onChange={debouncedInput}
                        />
                    </div>
                    <WatermarkTextOptions
                        options={watermark.customText?.options || {}}
                        submit={submit}
                        prefix="wct"
                    />
                </div>
            </Accordion>
        </div>
    );
}
