import {
    castFileLink,
    castFolderLink,
    castLink,
    projectCastLink
} from '@he-novation/config/paths/herawFrontUris';
import { CastCommon, CastContentChild } from '@he-novation/config/types/cast.types';
import { FrontCast, FrontCastFolderChild } from '../types/cast.front-types';

export function mapCastContentChildToCast(
    item: CastContentChild,
    {
        castUid,
        isPrivate,
        projectUuid,
        workspaceName
    }: {
        castUid: string;
        isPrivate?: boolean;
        projectUuid?: string;
        workspaceName: string;
    }
): FrontCastFolderChild {
    if (item.type === 'file') {
        let href: string | undefined;
        if (!isPrivate) {
            href = castFileLink(castUid, item.castFileUuid);
        }
        return {
            castFileUuid: item.castFileUuid,
            uuid: item.uuid,
            name: item.name,
            created: item.created ? new Date(item.created) : item.created,
            type: 'file',
            thumbnail: item.assets?.find((a) => a.type === 'thumbnail')?.url,
            version: item.version,
            assets: item.assets,
            href,
            user: item.creator,
            folder: item.folder!,
            mimeType: item.assets?.find((a) => a.quality === 'source')?.mimeType || '',
            isPlayable: item.assets?.some((a) => a.type === 'player') || false,
            status: item.status
        };
    }

    return {
        ...item,
        href: isPrivate
            ? projectUuid
                ? projectCastLink(workspaceName, projectUuid, castUid, item.uuid)
                : castLink(workspaceName, castUid, item.uuid)
            : castFolderLink(castUid, item.uuid)
    };
}

export function mapCastToFrontCast(
    cast: CastCommon,
    isPrivate?: boolean,
    projectUuid?: string
): FrontCast {
    return {
        ...cast,
        isPrivate: !!isPrivate,
        created: cast.created ? new Date(cast.created) : cast.created,
        expires: cast.expires ? new Date(cast.expires) : cast.expires,
        content: cast.content.map((item) =>
            mapCastContentChildToCast(item, {
                castUid: cast.uid,
                isPrivate,
                projectUuid,
                workspaceName: cast.client.name
            })
        )
    };
}
